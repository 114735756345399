import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/layout";

const Teremberles = ({ data }) => (
  <Layout>
    <div className="container content-wrap">
      <div className="row my-3">
        <div className="col">
          <h1>Terembérlés</h1>
        </div>
      </div>

      <h2 class="mb-4">Otthont adunk a programodnak!</h2>
      <div class="row">
        <div class="col-md-4">
          <GatsbyImage
            image={getImage(data.terem1)}
            alt="Otthont adunk a programodnak!"
            className="card-img-top img-fluid mx-auto d-block"
          />
        </div>
        <div class="col-md-8">
          <p>
            Történetünk valamikor az 50-es években kezdödött, amikor a
            nagyszüleink a Pauler utca 8. szám alá költöztek. Ekkor kezdte meg
            családunk ebben a -ma már múemlékvédettséget élvezö- épületben élni
            az életét.
          </p>
          <p>
            A Szelence Home és Szelence Café egy családi tulajdonú vállalkozás
            az I. kerület szívében. Célja, hogy olyan hangulatot és környezetet
            teremtsen az itt megrendezett programoknak, mely biztosítja a
            résztvevök feszültségmentes, könnyed jelenlétet, ezáltal az
            események aktívabb és hatékonyabb megélését.
          </p>
          <p>Pont úgy, mint saját otthonaikban.</p>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-md-4 mb-3">
          <div class="card h-100">
            <div class="card-body">
              <h3 className="card-title">Kávézó</h3>
              <p>
                A Szelence Café egészséges ételeket és italokat kínál minden
                vendége számára. Vegán ételeink, italaink mellett azonban azokra
                is gondolunk, akik szívesebben étkeznek hagyományosan. Konyhánk
                és cukrászmühelyünk mindenki számára elérhetó kínálattal
                rendelkezik.
              </p>
            </div>
          </div>
        </div>

        <div class="col-md-4 mb-3">
          <div class="card h-100">
            <div class="card-body">
              <h3 className="card-title">Terembérlés</h3>
              <p>
                A Szelence Home otthonunk vintage hangulatú termeit kínálja
                céges vagy privát rendezvények számára. A helyiségek kialakítása
                azt a célt szolgálja, hogy abetéró vendégek ellazult, otthonos
                környezetben befogadóbbá és nyitottabbá váljanak.
                Programötleteidnek csak a képzeleted szab határt!
              </p>
            </div>
          </div>
        </div>

        <div class="col-md-4 mb-3">
          <div class="card h-100">
            <div class="card-body">
              <h3 className="card-title">Catering</h3>
              <p>
                Terembérléseink mellé, valamint külsös rendezvényekhez is
                örömmel kínáljuk saját gyártású, helyben készült, egészséges
                catering termékeinket.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <h2 class="mb-4">Miért minket válassz?</h2>
        <p>
          Több, mint 10 éves rendezvényszervezési, lakberendezési, catering
          szolgáltatási és kreatív programszervezési tapasztalattal
          rendelkezünk. A Szelence Home valaha a mi otthonunk része volt, melyet
          szeretnénk mindenkivel megosztani. Caládunk tagjai a mai napig a
          Szelence Café-ban reggeliznek és ebédelnek. Vendégeinket személyesen
          is ismerjük, és sosem feledkezünk meg arról, hogy mosollyal üdvözöljk
          az érkezöket.
        </p>
      </div>

      <div className="row">
        <h2 class="mb-4">Szelence Home / Café terembérlési lehetöségek</h2>
        <p class="mb-4">
          Hiszünk az egyediségben és a személyre szabottság sikerében. Éppen
          ezért alábbi raink irányadóak számodra. Kérlek, vedd fel velünk a
          kapcsolatot, hogy a lehetó legjobb áron a legjobb szolgáltatást tudjuk
          neked nyújtani!
        </p>
        <div>
          <table className="table table-responsive">
            <thead class="">
              <tr>
                <th scope="col"></th>
                <th scope="col">Szelence Café</th>
                <th scope="col">Szelence Home</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>alapár</td>
                <td>54,000 HUF / 2 óra</td>
                <td>35,000 HUF / 2 óra</td>
              </tr>
              <tr>
                <td>minden további óra</td>
                <td>9,900 HUF</td>
                <td>9,900 HUF</td>
              </tr>
              <tr>
                <td>min. foglalási idó</td>
                <td>3 óra</td>
                <td>3 óra</td>
              </tr>
            </tbody>
          </table>

          <h4>Jó, ha tudod</h4>
          <ul>
            <li>a termet igény szerint berendezzük neked</li>
            <li>nincs kötelezó fogyasztás!</li>
            <li>saját ételeidet és italaidat bátran hozhatod magaddal!</li>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
);

export const pageQuery = graphql`
  query {
    terem1: file(relativePath: { eq: "room/terem.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

export const Head = () => <title>Szelence Café | Terembérlés</title>;

export default Teremberles;
